<template>
  <div>
    <v-overlay
      :absolute="absolute"
      :opacity="opacity"
      :z-index="zIndex"
    >
      <v-progress-circular
        v-if="loading"
        :rotate="360"
        :size="100"
        :width="15"
        color="#FF3700"
      />
      <h1 class="message-color">
        {{ message }}
      </h1>
    </v-overlay>
  </div>
</template>
<script>
  import Constants from 'src/constants';

  export default {
    name: 'PasswordResettingProcess',
    data () {
      return {
        loading: false,
        message: '',
        absolute: false,
        opacity: 0.6,
        zIndex: 5,
      };
    },
    mounted () {
      this.startPasswordResetting();
      this.storeExternalId();
    },
    methods: {
      async startPasswordResetting () {
        this.message = 'Fetching Details...';
        this.loading = true;
        const externalId = this.$route.params.userId;
        const updateId = this.$route.params.linkId;
        await this.$store.dispatch('passwordreset/createLinkActiveId', {
          user_id: externalId,
          update_id: updateId,
          type: Constants.USER_PASSWORD_RESET,
        }).then(response => {
          this.$router.push({ name: 'Create New Password' });
        }).catch(() => {
          this.$router.push({ name: 'Login' });
        }).finally(
          this.loading = false,
        );
      },
      storeExternalId () {
        const externalId = this.$route.params.userId;
        this.$store.dispatch('passwordreset/storeExternalId', {
          id: externalId,
        });
      },
    },
  };
</script>
<style scoped>
.message-color {
  color: white;
}
</style>
